// Auth
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const GET_ERRORS = 'GET_ERRORS';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';

// Profile
export const GET_PROFILE = 'GET_PROFILE';
export const GET_QUESTIONS = 'GET_QUESTIONS';
export const PROFILE_LOADING = 'PROFILE_LOADING';
export const QUESTION_RESET = 'QUESTION_RESET';
export const QUESTIONS_LOADING = 'QUESTIONS_LOADING';
export const SET_ALERTED = 'SET_ALERTED';
export const SET_ASSIGNMENT = 'SET_ASSIGNMENT';
export const UPDATE_ANSWERED = 'UPDATE_ANSWERED';
export const UPDATE_PAGENUMBER = 'UPDATE_PAGENUMBER';
export const UPDATE_QUESTIONS = 'UPDATE_QUESTIONS';
export const UPDATE_SELECTED = 'UPDATE_SELECTED';
export const UPDATE_TASKS = 'UPDATE_TASKS';

// Text Editor
export const UPDATE_TEXT = 'UPDATE_TEXT';
